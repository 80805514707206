ui-cropper {
  width: 100%;
  display: block; }
  ui-cropper.fixed-height {
    height: 100%;
    position: relative;
    overflow: hidden; }
    ui-cropper.fixed-height canvas {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%; }
  ui-cropper.full-width {
    text-align: center; }
    ui-cropper.full-width canvas {
      display: inline; }
  ui-cropper canvas {
    outline: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    /* mobile webkit */ }
  ui-cropper .loading {
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-justify-content: center;
    color: white;
    background-color: rgba(0, 0, 0, 0.75); }
    ui-cropper .loading .fixed-height {
      height: 100%;
      position: absolute; }
